<template>
  <!--
    Show the configuration changes
  -->
  <Portlet
    title="Configuration history"
    icon="cogs"
    class="configurationHistory"
  >
    <template
      v-if="configurations && configurations.length > 5"
      slot="buttons"
    >
      <toggle-button
        v-model="showAllConfigurations"
        :labels="{ checked: 'all', unchecked: 'last 5' }"
        :width="133"
        :height="30"
        :font-size="12"
        class="ml-3 mt-2"
      />
    </template>
    <div
      v-if="showAllConfigurations"
    >
      <div
        v-for="(item, index) in configurations"
        :key="`ConfigCompare-${ index }`"
      >
        <ConfigurationHistoryItem
          :configuration="item"
          @restoreConfig="restoreConfig"
        />
      </div>
    </div>
    <div
      v-else
    >
      <div
        v-for="(item, index) in lastConfigurations"
        :key="`ConfigCompare-${ index }`"
      >
        <ConfigurationHistoryItem
          :configuration="item"
          @restoreConfig="restoreConfig"
        />
      </div>
    </div>
  </Portlet>
</template>

<script>

export default {
  name: 'ConfigurationHistory',
  components: {
    ConfigurationHistoryItem: () => import('@/components/Config/ConfigurationHistoryItem.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return{
      loading: true,
      configurations: null,
      lastConfigurations: null,
      showAllConfigurations: false
    }
  },
  created () {
    this.getHistory();
  },
  methods: {
    getHistory () {
      this.axios.get(`/Config/GetConfigHistory?installationId=${ this.installationId }`)
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.configurations = response.data;
          this.lastConfigurations = this.configurations.slice(0, 5); //configHistory is sorted by createDate on serverSide
        }
      })
    },
    restoreConfig (componentTypeId, configurationId, laneNumber) {
      let resetRequest = {
        'InstallationId': this.installationId,
        'ComponentTypeId': componentTypeId,
        'ConfigurationId': configurationId,
        'LaneNumber': laneNumber
      };
      this.axios.post(`/Config/ResetConfig`, resetRequest)
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$snotify.success('Config restore done');
        }
      })
    }
  }
}
</script>